<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" detail>
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id v-model="model.id" label="id" disabled />
        <ns-company :value="model.companyId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.mobileType" enum-name="MobileType" label="mobileType" disabled />
        <n-field-enum v-model="model.status" enum-name="CustomerOrderStatus" label="status" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-selection v-model="model.productId" field-name="productId" selection-name="company.product" label="product" disabled />
        <n-field-text :value="model.storeProductId" label="storeProductId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime v-model="model.orderTime" label="orderTime" disabled />
        <n-field-datetime v-model="model.expireTime" label="expireTime" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.andOrderId" label="andOrderId" disabled />
        <n-field-text :value="model.andPurchaseToken" label="andPurchaseToken" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id :value="model.iosItemId" label="iosItemId" disabled />
        <n-field-text :value="model.iosTransactionId" label="iosTransactionId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-text :value="model.iosOriginalTransactionId" label="iosOriginalTransactionId" disabled />
        <n-field-text :value="model.iosReceiptData" label="iosReceiptData" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-decimal v-model="model.orderPrice" label="orderPrice" disabled />
        <n-field-text :value="model.orderCurrency" label="orderCurrency" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-date v-model="model.orderDate" label="orderDate" disabled />
        <n-field-date v-model="model.expireDate" label="expireDate" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalCustomerOrder',
  components: { NModal },
});
</script>
